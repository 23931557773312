<template>
  <div class="container-sm">
    <br><br><br><br><br>
    <router-link
      :to="{ name: 'home' }"
      style="color: #000"
    >
      <div class="d-flex align-items-center">
        <div class="box-back text-center mr-1">
          <i class="fas fa-long-arrow-left" />
        </div>
        <h5 class="text-center font-weight-bolder m-0">
          ถอนเครดิต
        </h5>
      </div>
    </router-link>

    <div class="card p-1 mt-1">
      <div class="text-center header-dark">
        <h5 class="font-weight-bolder m-0">
          ถอนเครดิตเข้าบัญชีธนาคาร
        </h5>
      </div>

      <div>
        <div>
          <div
            v-if="agent.status_wit === '0'"
            class="text-center"
          >
            <small
              class="text-danger"
            >*ขออภัยขณะนี้เว็บไซต์ได้ทำการปิดระบบถอนเงินชั่วคราว</small>
          </div>
          <div
            v-else
            class="text-center"
          >
            <small
              class="text-yellow"
            >*ระบบจะโอนเงินเข้าบัญชีของท่าน ภายในเวลา 1-2 นาที</small>
          </div>

          <div class="bg-tab">
            <div class="tab-list">
              <div
                class="tab-items"
                :class="{ active: isActive === 'bank' }"
                :disabled="!User.acc_no"
                @click="selected('bank')"
              >
                บัญชีธนาคาร
              </div>

              <div
                class="tab-items"
                :class="{ active: isActive === 'true' }"
                :disabled="!User.trueaccno"
                @click="selected('true')"
              >
                ทรูมันนี่ วอลเล็ต
              </div>

              <div
                v-if="agent.check_wit_crypto === 1"
                class="tab-items"
                :class="{ active: isActive === 'crypto' }"
                @click="selected('crypto')"
              >
                Crypto (USDT)
              </div>
            </div>

            <div class="p-1 rounded-bottom">
              <!-- Bank Account -->
              <div
                v-if="isActive === 'bank'"
                class="text-dark"
              >
                <div
                  class="card-bank p-1"
                >
                  <div class="d-flex align-items-center">
                    <div>
                      <img
                        :src="`/bankIcon/${User.path_photo}`"
                        alt="bank"
                        height="35"
                        class="mr-50"
                      >
                    </div>
                    <div>
                      <h5
                        class="m-0 font-weight-bolder"
                        style="color: #000"
                      >
                        {{ User.bankname }}
                      </h5>
                    </div>
                  </div>

                  <div class="mt-1 mb-1 d-flex justify-content-between">
                    <img
                      src="/chip.png"
                      alt="chip"
                      height="40"
                    >
                  </div>

                  <div>
                    <div class="box-bank">
                      <h4 class="m-0 dark font-weight-bolder">
                        {{ User.acc_no }}
                      </h4>
                    </div>
                    <div>
                      <h5 class="m-0 dark">
                        {{ User.name }} {{ User.surname }}
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="mt-1">
                  <input
                    id="balance"
                    v-model="amount"
                    :disabled="agent.status_wit === '0'"
                    type="number"
                    name="balance"
                    placeholder="จำนวนที่ต้องการถอน"
                    class="form-control"
                  >

                </div>
                <button
                  :disabled="agent.status_wit === '0' || loading === true"
                  class="mt-1 btn-theme-two btn btn-block"
                  @click="submit()"
                >
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-1"
                  />
                  ถอนเงิน
                </button>
              </div>

              <!-- True Wallet -->
              <div
                v-if="isActive === 'true'"
                class="text-dark"
              >
                <div
                  class="card-bank p-1"
                >
                  <div class="d-flex align-items-center">
                    <div>
                      <img
                        src="/bankIcon/true.svg"
                        alt="bank"
                        height="35"
                        class="mr-50"
                      >
                    </div>
                    <div>
                      <h5
                        class="m-0 font-weight-bolder"
                        style="color: #000"
                      >
                        ทรูมันนี่ วอลเล็ต
                      </h5>
                    </div>
                  </div>

                  <div class="mt-1 mb-1 d-flex justify-content-between">
                    <img
                      src="/chip.png"
                      alt="chip"
                      height="40"
                    >
                  </div>

                  <div>
                    <div class="box-bank">
                      <h4 class="m-0 dark font-weight-bolder">
                        {{ User.trueaccno }}
                      </h4>
                    </div>
                    <div>
                      <h5 class="m-0 dark">
                        {{ User.name }} {{ User.surname }}
                      </h5>
                    </div>
                  </div>
                </div>

                <div class="mt-1">
                  <input
                    id="balance"
                    v-model="amount"
                    :disabled="agent.status_wit === '0'"
                    type="number"
                    name="balance"
                    placeholder="จำนวนที่ต้องการถอน"
                    class="form-control"
                  >

                </div>
                <button
                  :disabled="agent.status_wit === '0' || loading === true"
                  variant="primary"
                  class="mt-1 btn-theme-two btn btn-block"
                  @click="submit()"
                >
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-1"
                  />
                  ถอนเงิน
                </button>
              </div>

              <!-- Crypto -->
              <div
                v-if="isActive === 'crypto'"
                class="text-dark"
              >
                <div>

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <img
                        src="/bankIcon/usdt.png"
                        alt=""
                        height="50"
                        class="mr-50"
                      >
                    </div>

                  </div>
                  <div class="my-1">
                    <small class="text-muted">USDT Withdraw Address</small>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 text-warning font-medium-2">
                        {{ User.crypto_accno }}
                      </p>
                    </div>
                  </div>

                  <div class="my-1">
                    <small class="text-muted">Network</small>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="mb-0 text-muted">
                        BNB Smart Chain (BEP20)
                      </p>

                      <i class="fas fa-exchange text-muted" />
                    </div>
                  </div>

                </div>

                <div class="card-noti p-2">
                  <div class="d-flex justify-content-between align-items-center my-25">
                    <small>Min withdraw</small>

                    <small class="text-white">>1.00 USDT</small>
                  </div>

                  <div class="d-flex justify-content-between align-items-center my-25">
                    <small>Exp arrival</small>

                    <small class="text-white">15 network confirmations</small>
                  </div>

                  <div class="d-flex justify-content-between align-items-center my-25">
                    <small>Exp unlock</small>

                    <small class="text-white">15 network confirmations</small>
                  </div>
                </div>

                <div class="mt-1">
                  <input
                    id="balance"
                    v-model="amount"
                    :disabled="agent.status_wit === '0'"
                    type="number"
                    name="balance"
                    placeholder="จำนวนที่ต้องการถอน"
                    class="form-control"
                  >

                </div>
                <button
                  variant="primary"
                  :disabled="agent.status_wit === '0' || loading === true"
                  class="mt-1 btn-theme-two btn btn-block"
                  @click="submitCoin()"
                >
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-1"
                  />
                  ถอนเงิน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="text-left text-head-hisotry mt-2">
      ประวัติการถอนเงิน [รายการล่าสุด 5 รายการ]
    </div>

    <div
      class="
        w-100
        border-solid
        rounded-lg
        text-color
        bg-table
        border-10
        table-responsive
      "
    >
      <table
        role="table"
        aria-busy="false"
        aria-colcount="3"
        class="table b-table table-sm"
      >
        <thead role="rowgroup">
          <tr role="row">
            <th
              role="columnheader"
              scope="col"
              aria-colindex="1"
              class="small text-left pl-1"
            >
              <div>วันที่/เวลา</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="2"
              class="small text-center pr-1"
            >
              <div>จำนวนเงิน</div>
            </th>
            <th
              role="columnheader"
              scope="col"
              aria-colindex="3"
              class="small text-center pr-1"
            >
              <div>สถานะ</div>
            </th>
          </tr>
        </thead>
        <tbody
          v-if="withdrawData.length > 0"
          role="rowgroup"
        >
          <tr
            v-for="(key, index) in withdrawData"
            :key="index._id"
            role="row"
            class="b-table-empty-row"
          >
            <td>{{ key.timestamp }}</td>
            <td class="text-center">
              {{ key.amount }}บาท
            </td>
            <td class="text-center">
              <b-badge
                v-if="key.status === 'processing'"
                variant="light-warning"
              >
                <small> กำลังดำเนินการ</small>
              </b-badge>
              <b-badge
                v-if="key.status === 'waiting' || key.status === 'error'|| key.status === 'waiting_approve'"
                variant="light-warning"
              >
                <small> รอดำเนินการ</small>
              </b-badge>
              <b-badge
                v-if="key.status === 'manual_success' || key.status === 'success'"
                variant="light-success"
              ><small> สำเร็จ</small>
              </b-badge>
              <b-badge
                v-if="key.status === 'return'"
                variant="light-success"
              ><small> คืนเครดิต สำเร็จ</small>
              </b-badge>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr
            role="row"
            class="b-table-empty-row"
          >
            <td
              colspan="3"
              role="cell"
            >
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-color text-center pt-2 pb-2 fs-14">
                  ไม่พบข้อมูล
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      ref="confirm-modal"
      hide-footer
      hide-header
      centered
      class="bg-dark"
    >
      <div class="text-center">
        <img
          src="/usd.png"
          alt=""
          height="120"
        >

        <h3 class="text-black mb-0">
          1 USDT ≈ {{ Commas(crypto.rate_thb) }} THB
        </h3>
        <h4 class="text-black mb-0">
          Recive: {{ Commas(crypto.usdt) }} USDT ≈ {{ Commas(Commas(crypto.usdt-crypto.fee) * crypto.rate_thb) }} THB
        </h4>

        <p class="text-black">
          Fee : {{ Commas(crypto.fee) }} USDT ≈ {{ Commas(crypto.fee * crypto.rate_thb) }} THB
        </p>

        <b-button
          variant="primary"
          class="mt-1 btn-theme-two"
          block
          @click="confirmCoin()"
        >
          ยืนยันข้อมูล
        </b-button>
      </div>
    </b-modal>
    <br><br><br><br><br>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import {
  BBadge, BButton, BSpinner, BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BButton,
    BSpinner,
    BModal,
  },
  props: {
    // eslint-disable-next-line
    // UserProfile: Object,
  },
  data() {
    return {
      isActive: 'bank',
      selecteder: '',
      loading: false,
      show: true,
      balance: 0.0,
      amount: '',
      withdrawData: '',
      UserData: JSON.parse(localStorage.getItem('userData')),
      User: {},
      wto: 'bank',
      agent: {},
      crypto: {
        rate_thb: 0,
        fee: 0,
        usdt: 0,
      },
    }
  },
  mounted() {
    this.GetAgentData()
    this.Getbalance()
    this.GetUser(this.UserData.userID)
    this.getwithdraw(this.UserData.username)
  },
  methods: {
    confirmCoin() {
      this.$refs['confirm-modal'].hide()
      this.submit()
    },
    submitCoin() {
      this.show = true
      this.loading = true
      // this.amount = this.balance.balance
      if (Number(this.amount) <= Number(this.balance)) {
        if (this.wto === null) {
          this.show = false
          this.loading = true
          this.SwalError('กรุณาเลือกบัญชีที่ต้องการให้เว็บโอนเข้า')
        } else {
          const params = {
            toAddress: this.User.crypto_accno,
            amount: this.amount,
          }
          // console.log(params)
          this.$http
            // .post('http://127.0.0.1:8888/api/withdraw/checkgas', params)
            .post('/withdraw/checkgas', params)
            .then(response => {
              this.show = false
              this.loading = false
              const res = response.data
              if (res.status) {
                console.log(res)
                this.$refs['confirm-modal'].show()
                this.crypto.rate_thb = res.data.rate_thb
                this.crypto.fee = res.data.fee
                this.crypto.usdt = res.data.usdt
              } else {
                this.SwalError(res.msg)
              }
            })
            .catch(error => {
              this.show = false
              this.loading = false
              this.SwalError(error.response.data.msg)
            })
        }
      } else {
        this.show = false
        this.loading = false
        this.SwalError('ยอดเครดิต ไม่เพียงพอสำหรับการถอน')
      }
    },
    selected(val) {
      this.isActive = val
      this.wto = val
    },
    GetUser(userID) {
      this.$http
        .get(`/users/show/${userID}`)
        .then(response => {
          // console.log(response.data.ListGames)
          this.User = response.data
          // console.log(this.User)
        })
        .catch(error => console.log(error))
    },
    Getbalance() {
      this.$http
        .get('/balance/getbalance')
        .then(response => {
          // console.log(response.data)
          this.balance = response.data.balance
          if (this.balance < 0) {
            this.balance = 0
          }
        })
        .catch(error => console.log(error))
    },
    getwithdraw(username) {
      this.show = true
      this.$http
        .get(`/withdraw/username/${username}`)
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.withdrawData = response.data
          let index = 0
          this.withdrawData.forEach(items => {
            this.withdrawData[index].timestamp = moment(items.created_at)
              .tz('Asia/Bangkok')
              .format('DD/MM/YYYY HH:mm:ss')
            index += 1
          })
        })
        .catch(error => console.log(error))
    },
    GetAgentData() {
      this.$http
        .get('/agent/show')
        .then(response => {
          // console.log(response.data.ListGames)
          this.agent = response.data
        })
        .catch(error => console.log(error))
    },
    submit() {
      this.show = true
      this.loading = true
      // this.amount = this.balance.balance
      if (Number(this.amount) <= Number(this.balance)) {
        if (this.wto === null) {
          this.show = false
          this.SwalError('กรุณาเลือกบัญชีที่ต้องการให้เว็บโอนเข้า')
        } else {
          const params = {
            username: this.User.username,
            amount: this.amount,
            uid: this.User.id,
            wto: this.wto,
          }
          // console.log(params)

          this.$http
            .post('/withdraw/store', params)
            // .post('http://127.0.0.1:8888/api/withdraw/store', params)
            .then(response => {
              this.show = false
              this.loading = false
              this.amount = ''
              this.Success(response.data.message)
              this.getbalance(this.User.username)
              this.getwithdraw(this.User.username)
            })
            .catch(error => {
              this.show = false
              this.loading = false
              this.SwalError(error.response.data.message)
            })
        }
      } else {
        this.show = false
        this.loading = false
        this.SwalError('ยอดเครดิต ไม่เพียงพอสำหรับการถอน')
      }
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกเลขบัญชีสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color:#000">ทำรายการสำเร็จ<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
  },
}
</script>

<style scoped>
/* .btn-theme-two {
  width: 100%;
  background: linear-gradient(180deg, #5a499d, #bd6aaa);
  border-radius: 10px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 38px;
  color: #FFFFFF !important;
  font-size: 1.2rem !important;
  font-weight: normal !important;
  border-color: #f2994a !important;
} */
.btn2 {
  font-size: 1rem !important;
}
.form-control {
  background: #ffffff !important;
  border-radius: 14px !important;
  color: #232323 !important;
  height: 38px !important;
  font-size: 1.2rem !important;
  filter: drop-shadow(0 0 1px #f2994a);
}
.card-bank {
  min-height: 175px;
  border-radius: 14px;
  /* background-image: url('/bg-bank2.svg'); */
  background: linear-gradient(
      0deg,
      rgba(185, 153, 91, 0.9),
      rgba(221, 204, 151, 0.9),
      rgba(185, 153, 91, 0.9)
    ),
    url("/bg-bank2.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 6px;
  padding: 10px;
}
.dark {
  color: #000 !important;
}
.header-dark {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  padding: 10px;
}
.card-list {
  margin: 0 15px;
  background: #2e2e2e;
  box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
  border-radius: 10px;
  padding: 10px;
}

.card {
  border-radius: 14px;
  border: 1px solid rgb(51, 51, 51);
  background: #232323 !important;
  color: rgb(255, 255, 255) !important;
}
.text-yellow {
  color: #ffc107 !important;
}
.bg-balance {
  background-image: url(https://www.ltobet.com/assets/img/bg-banner-home.svg) !important;
  background-repeat: repeat !important;
  background-size: 100% auto !important;
}
.btn-withdraw {
  height: 36px;
  background-color: #ffffff27;
  color: #fff !important;
  border: 1px solid #fff;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.btn-depo {
  height: 36px;
  background: #fff;
  border: none;
  font-weight: 700;
  padding: 0 6px;
  border-radius: 2px !important;
}
.theme-ltobet .book_bank_content .bank-icon .media-icon {
  width: 20px;
  height: 20px;
  display: block;
  float: left;
  border-radius: 4px;
  position: relative;
}
.btn-orange {
  background-image: linear-gradient(103deg, #0c2574 1%, #341082);
  width: 100%;
  border-radius: 10px !important;
  color: #ffffff !important;
}
.box-ac-items {
  padding: 10px;
  border-radius: 5px;
  border: #000 1px solid;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.btnlogout {
  background: linear-gradient(180deg, #626166, #3d3a3d) !important;
  color: rgb(255, 255, 255);
  width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  padding: 0 6px;
  height: 36px;
}
</style>
